import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import styled from 'styled-components'
import { Header, Layout } from '../components/common'

const CookieDeclarationPage: React.FC = () => {
  useEffect(() => {
    // WORKAROUND: Asynchronously add DOM manipulating script
    const script = document.createElement('script')
    script.async = true
    script.id = 'CookieDeclaration'
    script.src =
      'https://consent.cookiebot.com/07b90fa4-8666-443b-97c2-ceb64919d2da/cd.js'
    document.getElementById('declaration')!.appendChild(script)
  }, [])

  return (
    <Layout
      seo={{
        title: 'Cookie declaration',
      }}
    >
      <Header title="Dichiarazione dei cookie" />
      <StyledContainer id="declaration" />
    </Layout>
  )
}

export default CookieDeclarationPage

const StyledContainer = styled(Container)`
  text-align: justify;
`
